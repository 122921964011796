"use client";
import Image from "next/image";
import { useState } from "react";
import { createPublicUser } from "@/src/actions/user/user";
import { Button, Form, Spin } from "antd";
import FormInput from "@components/form-items/FormInput";
import React from "react";
import { useTranslation } from "@/app/i18n/client";
import { signIn } from "next-auth/react";
import { checkPasswordRetype, validateMessages } from "@/src/lib/validation";

const rules = {
  name: [{ required: true }],
  email: [{ required: true }, { type: "email" }],
  password: [{ required: true }],
  confirmPassword: [{ required: true }, checkPasswordRetype],
};
export default function SignUpForm() {
  const { t } = useTranslation("common");
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<String | undefined>(undefined);
  const [errors, setErrors] = useState<any>(undefined);

  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async () => {
        const values = form.getFieldsValue() || {};
        delete values.confirmPassword;
        setIsLoading(true);
        const data = await createPublicUser(values);

        if (data?.error && typeof data.error === "string") {
          setError(data.error);
          return;
        } else if (data?.error && typeof data.error === "object") {
          setErrors(data.error);
          return;
        }
        // Handle login after sign up
        if (data?.data && data.data.id) {
          const dataLogin = await signIn("credentials", {
            redirect: false,
            email: values.email,
            password: values.password,
          });
          if (dataLogin?.ok && dataLogin.status === 200) {
            window.location.href = "/";
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={isLoading} tip="Loading...">
      <Form
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        className="custom-form"
        validateMessages={validateMessages}
      >
        <div className="flex w-full items-center justify-center">
          <Image
            alt="Platforms Starter Kit"
            width={100}
            height={100}
            className="relative mx-auto h-12 w-auto dark:scale-110 dark:rounded-full dark:border dark:border-stone-400"
            src="/logo.png"
          />
        </div>
        <h1 className="font-cal mt-6 text-center text-3xl dark:text-white">
          {t("signup.title")}
        </h1>

        <div className="py-6">
          <div className="space-y-4 md:space-y-6">
            <div>
              <FormInput
                name="name"
                placeholder="your name"
                className="focus:ring-primary-600 focus:border-primary-600 mb-0 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 sm:text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500
                 dark:focus:ring-blue-500"
                label={t("signup.name")}
                rule={rules.name}
              ></FormInput>
            </div>
            <div>
              <FormInput
                name="email"
                placeholder="name@company.com"
                className="focus:ring-primary-600 focus:border-primary-600 mb-0 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 sm:text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500
                 dark:focus:ring-blue-500"
                label={t("login.email")}
                rule={rules.email}
                extra={
                  errors &&
                  errors.email &&
                  errors.email?.length > 0 && (
                    <div className="text-red-700">{errors.email}</div>
                  )
                }
                inputProps={{ onPressEnter: handleSubmit }}
              ></FormInput>
            </div>
            <div>
              <FormInput
                name="password"
                type="password"
                placeholder="••••••••"
                className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 sm:text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                label={t("login.password")}
                rule={rules.password}
                extra={
                  errors &&
                  errors.password &&
                  errors.password?.length > 0 && (
                    <div className="text-red-700">{errors.password}</div>
                  )
                }
                inputProps={{ onPressEnter: handleSubmit }}
              ></FormInput>
            </div>

            <div>
              <FormInput
                name="confirmPassword"
                type="password"
                placeholder="••••••••"
                className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 sm:text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                label={t("signup.confirmPassword")}
                rule={rules.confirmPassword}
                inputProps={{ onPressEnter: handleSubmit }}
              ></FormInput>
            </div>
          </div>
          {error && error.length > 0 && (
            <div className="text-red-700">{error}</div>
          )}

          <Button
            className="mt-10 flex h-10 w-full items-center justify-center rounded-md border border-stone-200 transition-colors duration-75 focus:outline-none dark:border-stone-700"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {t("btn.signup")}
          </Button>
        </div>
      </Form>
    </Spin>
  );
}
