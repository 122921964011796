"use client";
import { useRouter, useSearchParams } from "next/navigation";
import { usePropertyStore } from "@stores/rootStoreProvider";
import { useCallback, useEffect, useRef, useState } from "react";
import { SearchIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

export default function Search() {
  const propertyStore = usePropertyStore();
  const searchParams = useSearchParams();
  const router = useRouter();
  const [searchValue, updateSearchValue] = useState<any>();
  const [isSticky, setIsSticky] = useState(false);
  const { t } = useTranslation("common");
  // create element ref
  const innerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Get a new searchParams string by merging the current
  // searchParams with a provided key/value pair
  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams],
  );

  const updateValue = (e: any) => {
    updateSearchValue(e?.target.value);
  };

  const handleSubmit = async () => {
    router.replace("/search?" + createQueryString("q", searchValue));
    propertyStore.setParams({
      ...propertyStore.params,
      q: searchValue,
    });
  };

  return (
    <div
      ref={innerRef}
      className={
        "top-0 z-[100] flex h-[65px] items-center justify-center" +
        (isSticky ? " sticky mx-8" : "")
      }
    >
      <div
        id="search-form"
        className="custom-form relative flex h-[45px] w-[80vw] max-w-[710px] items-center rounded-3xl bg-slate-50 !px-5 sm:w-[65vw] lg:w-[50vw] xl:w-[35vw]"
      >
        <div className="flex flex-1">
          <i className="icon-search"></i>

          <input
            type="text"
            placeholder={t("header.searchOnSite")}
            className="!placeholder:text-slate-200 !h-auto min-h-[16px] w-full rounded-lg !border-none !bg-transparent !px-0 text-gray-900 !shadow-none ring-0 placeholder:text-sm focus:ring-0 sm:text-sm"
            onKeyDown={async (e) => {
              if (e.key !== "Enter") {
                return;
              }
              const { value } = e.currentTarget;
              router.replace("/search?q=" + value);
            }}
            onChange={updateValue}
          />
        </div>
        <div className="cursor-pointer">
          <SearchIcon
            size="18"
            className="text-slate-400"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
